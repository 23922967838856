export const VIDEO_VISIT_LOADING = 'video-visit-loading';
export const VIDEO_VISIT_NO_TOKEN = 'Invalid token';
export const VIDEO_VISIT_NO_ID = 'Unable to fetch current user id';
export const GET_READY = 'Get Ready';
export const CHECK_AUDIO_VIDEO = 'Check your audio and video before joining call';
export const SESSION_ENDED = 'You have ended the session';
export const HAVE_NICE_DAY = 'Have a nice day';
export const REJOIN = 'Did you leave the session by mistake?';
export const ERR_CONNECT_CALL = 'Failed to connect to video call';
export const VIDEO_VISIT_NO_CALL_ID = 'Invalid call ID';
export const COACH_ROLE = 'Coach';
export const PT_ROLE = 'PT';
export const VIDEO_CALL_BUTTONS = {
  JOIN_ROOM: 'Join Room',
  END_CALL: 'End Call',
  REJOIN_ROOM: 'Rejoin Room',
  REJOIN: 'Rejoin'
};
export const VV_EXPAND_SNACKBAR_MESSAGE = 'Expand panel to view current session notes.';
export const VV_POOR_QUALITY_MESSAGE = 'Poor connection quality. Please check your internet connection.';
export const START_TIME = 'Start Time -';
export const ELAPSED_TIME = 'Session Time -';
export const CALL_DURATION = 'Call Duration -';
export const END_TIME = 'End Time -';
export const VIDEO_VISIT_TAB_CLOSE_EVENT = 'videoVisitTabClosed';
export const JOIN_ROOM_BUTTON_ENABLE_TIMEOUT = 5000;
export const PARTICIPANT_DISCONNECTED = 'Oops! Member has left the call abruptly';
export const MEMBER_HAS_NOT_JOINED = 'Member has not joined the call yet...';
export const MEMBER_JOINED = 'Member has joined the call';
export const TOKEN_NOT_SET = 'Token not set';
export const VIDEO_CALL_MIXPANEL_EVENTS = {
  JOIN_ROOM: 'Video Visit Join Room Clicked',
  VIDEO_VISIT_TAB_LAUNCHED: 'Video Visit Tab Launched',
  COMMS_PANEL_EXPANDED: 'Interactions Panel Expanded',
  RECORD_PANEL_ACCESSED: 'Member Record Panel Restored',
  CLICKED_CLOSE_VIDEO_VISIT_TAB: 'Video Visit Close Tab Clicked',
  CONNECTED_TO_VIDEO_CALL: 'Video Visit Connected',
  DISCONNECTED_FROM_VIDEO_CALL: 'Video Visit Disconnected',
  VIDEO_CALL_CONNECTION_INITIATED: 'Video Visit Connection Initiated',
  REJOINING_VIDEO_CALL: 'Video Visit Rejoined',
  CAMERA_TOGGLED: 'Video Visit Admin Camera Toggled',
  MIC_TOGGLED: 'Video Visit Admin Mic Toggled',
  MEMBER_IS_IN_THE_CALL: 'Video Visit Member has joined the call',
  MEMBER_NOT_IN_THE_CALL: 'Video Visit Member has not joined the call',
  MEMBER_DISCONNECTED: 'Video Visit Member Disconnected',
  MEMBER_CONNECTED: 'Video Visit Member Connected',
  REMOTE_AUDIO_TOGGLED: 'Video Visit Member Audio Toggled',
  REMOTE_VIDEO_TOGGLED: 'Video Visit Member Video Toggled',
  REMOTE_TRACK_DIMENSIONS_CHANGED: 'Video Visit Member Track Dimensions Changed',
  REMOTE_AUDIO_TRACK_SUBSCRIPTION: 'Video Visit Member Audio Track Subscribed',
  REMOTE_VIDEO_TRACK_SUBSCRIPTION: 'Video Visit Member Video Track Subscribed'
};
export const WORKFLOW_SELECTED_FROM_DASHBOARD = 'Workflow Selected from Dashboard';
export const LOCAL_PARTICIPANT = 'localParticipant';
export const REMOTE_PARTICIPANT = 'remoteParticipant';
export const TWILIO_ERRORS = {
  SIGNALLING_ERROR: 'Signaling connection error: connection disconnected or timed out. Please check your network and try again.',
  SIGNALLING_MESSAGE_ERROR: 'Error: There was a problem with the signal. Please try again.',
  VIDEO_SERVER_BUSY: 'Video server is busy. Please try again later.',
  ROOM_ERROR: 'Room error: There is a problem with the room name or connection.'
};
export const LAUNCH_VIDEO_VISIT = 'Launch Video Visit Call';
export const VIDEO_VISIT_LAUNCHED_FROM_PROFILE = 'Video Visit Launched from Profile';
export const VIDEO_VISIT_LAUNCHED_FROM_URL = 'Video Visit Launched from URL';
export const INITIAL_PARTICIPANT_COUNT = 1;
export const FINAL_PARTICIPANT_COUNT = 2;
export const STREAM_VIDEO_VISIT_REJOIN_CALL = 'Stream Video Visit Rejoin Call';
export const STREAM_VIDEO_CALL_END = 'Stream Video Call End';
export const TWILIO_VENDOR = 'twilio';
export const STREAM_VENDOR = 'stream';
export const VIDEO_TRACK_NOT_FOUND = 'Video track not found or camera permission denied.';
export const AUDIO_TRACK_NOT_FOUND = 'Audio track not found or microphone permission denied.';
export const RECORDING_DIALOG_TEXT = {
  CONSENT: {
    TITLE: 'Make sure everyone is ready',
    SUBTEXT: 'You should obtain consent to record this meeting from all participants, including external guests and guests who join late.',
    CONFIRM: 'Start',
    CANCEL: 'Cancel'
  },
  STOP: {
    TITLE: 'Are you sure you want to stop recording?',
    // eslint-disable-next-line quotes
    SUBTEXT: "You can't undo this action.",
    CONFIRM: 'Stop',
    CANCEL: 'Cancel'
  }
};
export enum RecordingStopReason {
  Manual = 'MANUAL',
  NetworkIssue = 'NETWORK_ISSUE',
  SessionEnded = 'SESSION_ENDED',
  RecordingFailed = 'RECORDING_FAILED',
}
export const RECORDING_FAILED_EVENT = 'RECORDING_FAILED_EVENT';
export const RECORDING_STOPPED_EVENT = 'RECORDING_STOPPED_EVENT';
export const RECORDING_START = 'Starting';
export const RECORDING_STOP = 'Stopping';
export const RECORDING = 'Recording';
export const RECORD = 'Record';
export const LOADING = 'loading';
export const STANDBY = 'standby';
export const CALL_RECORDING = 'recording';