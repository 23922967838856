import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useEffect, useMemo, useState } from 'react';
import { SseEvents } from '../constants/strings/events';
import { commonCoachCompletedStates, QueueCategoryType } from '../custom-types';
import { commonPtCompletedStates } from '../modules/dashboard/types/pt';
import { getEndOfDay, getStartOfDay } from '../modules/dashboard/utils/get-formatted-date';
import { useSplitTreatments } from '../modules/member-360/hooks/use-split-treatments';
import { OktaRole } from '../types/okta-roles';
import { getWorkflowSseData } from '../utils/coach-sse';
import { useGetQueueCountsCommonHook } from './use-get-queue-counts-common-hook';
import { useGetQueueWorkflowTypes } from './use-get-queue-workflow-types';
const getCompletedStates = (roles: OktaRole[] = []): string[] => roles.includes(OktaRole.PhysicalTherapist) ? commonPtCompletedStates : commonCoachCompletedStates;
export const useGetCompleteQueueCounts = (queueCategoryType?: QueueCategoryType | null, useStartAndEndDate?: boolean): {
  totalCount: string;
  errorInCountFetch: boolean;
  loading: boolean;
} => {
  const [totalCompleteCount, setTotalCompleteCount] = useState('0');
  const workflowTypesToCount = useGetQueueWorkflowTypes();
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const {
    enableWorkflowStateFilteringTreatment
  } = useSplitTreatments();
  const roles = (hingeHealthAuthState?.accessToken?.claims.roles as OktaRole[]);
  const isCoach = useMemo(() => roles?.includes(OktaRole.Coach), [roles]);
  const isOS = useMemo(() => roles?.includes(OktaRole.OnboardingSpecialist), [roles]);
  const useCoverage = isCoach || isOS;
  const completedStates = getCompletedStates(roles);
  const {
    totalCount,
    errorInCountFetch,
    loading,
    subscribedSseData,
    updateWorkflowCounts
  } = useGetQueueCountsCommonHook(workflowTypesToCount, {
    countWorkflowsFilters: {
      completedAfterDate: getStartOfDay(),
      completedBeforeDate: getEndOfDay(),
      ...(enableWorkflowStateFilteringTreatment ? {
        includeOnlyCompletedStates: true
      } : {
        completedStates
      }),
      useCoverage
    }
  }, queueCategoryType, useStartAndEndDate);
  useEffect(() => {
    if (subscribedSseData) {
      updateWorkflowCounts({
        subscribedSseData
      });
      const workflow = getWorkflowSseData(subscribedSseData);
      if ([...commonCoachCompletedStates, ...commonPtCompletedStates].includes(workflow.stateName) && workflow.type === SseEvents.WorkflowTransitioned) setTotalCompleteCount(`${+totalCompleteCount + 1}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedSseData]);
  useEffect(() => {
    setTotalCompleteCount(totalCount);
  }, [totalCount]);
  return {
    totalCount: totalCompleteCount,
    errorInCountFetch,
    loading
  };
};