import { Call, StreamVideoParticipant } from '@stream-io/video-react-sdk';
import dayjs from 'dayjs';
import { atom } from 'jotai';
import { LocalAudioTrack, LocalParticipant, LocalVideoTrack, RemoteParticipant, Room } from 'twilio-video';
import { RemoteParticipantStatus, VideoCallStatus } from '../constants/enum';
import { INITIAL_PARTICIPANT_COUNT, TOKEN_NOT_SET, TWILIO_VENDOR } from '../constants/video-visit';
export const basiliskPathwayLoadingAtom = atom<boolean>(false);
export const videoVisitLoadingAtom = atom<boolean>(false);
export const videoVisitErrorAtom = atom<string>('');
export const videoCallStatusAtom = atom<VideoCallStatus>(VideoCallStatus.Preview);
export const videoVisitTokenAtom = atom<string | undefined>(TOKEN_NOT_SET);
export const videoVisitRejoinAtom = atom<boolean>(false);
export const localTracksAtom = atom<Array<LocalAudioTrack | LocalVideoTrack>>([]);
export const localParticipantAtom = atom<LocalParticipant | undefined>(undefined);
export const remoteParticipantsAtom = atom<Array<RemoteParticipant>>([]);
export const roomAtom = atom<Room | null>(null);
export const memberStatusAtom = atom<RemoteParticipantStatus>(RemoteParticipantStatus.NotInTheCall);
export const currentTimeAtom = atom<string>('');
export const unMountedTimeAtom = atom<dayjs.Dayjs | null>(null);
export const sessionTimeAtom = atom<dayjs.Dayjs>(dayjs().set('hour', 0).set('minute', 0).set('second', 0));
export const isAcquiringLocalTracksAtom = atom<boolean>(false);
export const adminUuidAtom = atom<string>('');
export const userUuidAtom = atom<string>('');
export const basiliskPathwayIdAtom = atom<number>(0);
export const cameraEnabledAtom = atom<boolean>(true);
export const micEnabledAtom = atom<boolean>(true);
export const remoteMicEnabledAtom = atom<boolean>(true);
export const videoCallAtom = atom<{
  adminUuid: string | null;
  userUuid: string | null;
  videoCallId: string | null;
}>({
  adminUuid: null,
  userUuid: null,
  videoCallId: null
});
export const memberJoinedForTheFirstTimeAtom = atom<boolean>(false);
export const videoVisitWorkflowAtom = atom<boolean>(false);
export const disableJoinRoomButtonAtom = atom<boolean>(true);
export const numberOfParticipantsAtom = atom<number>(INITIAL_PARTICIPANT_COUNT);
export const remoteParticipantJoinedAtom = atom<boolean>(false);
export const roomIdentifierAtom = atom<string>('');
export const videoVisitVendorAtom = atom<string>(TWILIO_VENDOR);
export const streamCallAtom = atom<Call | null>(null);
export const streamRemoteParticipantsAtom = atom<StreamVideoParticipant[]>([]);