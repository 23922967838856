import { c as _c } from "react-compiler-runtime";
import { useClient } from '@splitsoftware/splitio-react';
import { useMemo } from 'react';
import { OsWorkflowTypes, PtWorkflowTypes, videoVisitWorkflowTypes } from '../../../custom-types';
import { ON, SPLIT_TREATMENTS } from '../constants/splits';
import { useShellContext } from '../contexts/shell.context';
import { DashboardType } from '../custom-types';
import { CoachDashboardFilters, CoachWorkflowTypes, commonExcludedStates, defaultCoachWorkflows } from '../types/coach';
import { HouseCallState } from '../types/house-calls';
import { defaultOsWorkflows, OsDashboardFilters } from '../types/os';
import { commonPtExcludedStates, defaultPtWorkflows, PtDashboardFilters } from '../types/pt';
import { VideoVisitsState } from '../types/video-visit';
import { CoachWorkflowFilter, OsWorkflowFilter, PtWorkflowFilter } from '../types/workflow-filter';
type AllDashboardFilters = CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters;
type SplitFilteredWorkflowTypes<T extends CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters> = Record<T, T extends CoachDashboardFilters ? CoachWorkflowFilter : T extends PtDashboardFilters ? PtWorkflowFilter : OsWorkflowFilter>;
type FilterStates<T extends DashboardType> = Record<T extends DashboardType.Coach ? CoachDashboardFilters : T extends DashboardType.PhysicalTherapist ? PtDashboardFilters : OsDashboardFilters, T extends DashboardType.Coach ? CoachWorkflowFilter : T extends DashboardType.PhysicalTherapist ? PtWorkflowFilter : OsWorkflowFilter>;
const useSplitTreatmentValue = treatment => {
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const isSplitTreatmentOn = splitClient?.getTreatment(treatment, {
    adminUuid: adminProfile?.uuid || "*"
  }) === ON;
  return isSplitTreatmentOn;
};
export const useFilterStates = dashboardType => {
  const $ = _c(11);
  const isPtActionPlanReviewWorkflowSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW);
  const isSetupActionPlanWorkflowSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW);
  const isCoachWeeklyGoalExpirySplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY);
  const isCoachMemberUpdatedWeeklyGoalSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL);
  const isCoachVideoVisitSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.COACH_VIDEO_VISIT);
  const isEnableWorkflowStateFilteringSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.ENABLE_WORKFLOW_STATE_FILTERING);
  let t0;
  if (dashboardType === DashboardType.Coach) {
    let t1;
    if ($[0] !== isCoachWeeklyGoalExpirySplitOn || $[1] !== isCoachMemberUpdatedWeeklyGoalSplitOn || $[2] !== isCoachVideoVisitSplitOn || $[3] !== isEnableWorkflowStateFilteringSplitOn) {
      let coachWorkflowsServedOn = [...defaultCoachWorkflows];
      if (isCoachWeeklyGoalExpirySplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachWeeklyGoalExpiry];
      }
      if (isCoachMemberUpdatedWeeklyGoalSplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal];
      }
      if (isCoachVideoVisitSplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachVideoVisit];
      }
      const filterStates = getFilterStatesForCoach(coachWorkflowsServedOn);
      t1 = filterExcludedStatesBySplit(filterStates, isEnableWorkflowStateFilteringSplitOn, commonExcludedStates);
      $[0] = isCoachWeeklyGoalExpirySplitOn;
      $[1] = isCoachMemberUpdatedWeeklyGoalSplitOn;
      $[2] = isCoachVideoVisitSplitOn;
      $[3] = isEnableWorkflowStateFilteringSplitOn;
      $[4] = t1;
    } else {
      t1 = $[4];
    }
    t0 = (t1 as FilterStates<T>);
  } else {
    if (dashboardType === DashboardType.PhysicalTherapist) {
      let t1;
      if ($[5] !== isPtActionPlanReviewWorkflowSplitOn || $[6] !== isSetupActionPlanWorkflowSplitOn || $[7] !== isEnableWorkflowStateFilteringSplitOn) {
        let ptWorkflowsServedOn = [...defaultPtWorkflows];
        const painUptickWorkflowsPt = [PtWorkflowTypes.PainUptick, PtWorkflowTypes.MemberPainUptick];
        if (isPtActionPlanReviewWorkflowSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.ActionPlanReview];
        }
        if (isSetupActionPlanWorkflowSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.SetupActionPlan];
        }
        const filterStates_0 = getFilterStatesForPt(ptWorkflowsServedOn, painUptickWorkflowsPt);
        t1 = filterExcludedStatesBySplit(filterStates_0, isEnableWorkflowStateFilteringSplitOn, commonPtExcludedStates);
        $[5] = isPtActionPlanReviewWorkflowSplitOn;
        $[6] = isSetupActionPlanWorkflowSplitOn;
        $[7] = isEnableWorkflowStateFilteringSplitOn;
        $[8] = t1;
      } else {
        t1 = $[8];
      }
      t0 = (t1 as FilterStates<T>);
    } else {
      let t1;
      if ($[9] !== isEnableWorkflowStateFilteringSplitOn) {
        const osWorkflowsServedOn = [...defaultOsWorkflows];
        const filterStates_1 = getFilterStatesForOs(osWorkflowsServedOn);
        t1 = filterExcludedStatesBySplit(filterStates_1, isEnableWorkflowStateFilteringSplitOn, commonExcludedStates);
        $[9] = isEnableWorkflowStateFilteringSplitOn;
        $[10] = t1;
      } else {
        t1 = $[10];
      }
      t0 = (t1 as FilterStates<T>);
    }
  }
  return t0;
};
export const getFilterStatesForCoach = (coachWorkflowsServedOn: CoachWorkflowTypes[]): SplitFilteredWorkflowTypes<CoachDashboardFilters> => ({
  [CoachDashboardFilters.All]: {
    workflowTypes: coachWorkflowsServedOn,
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.UnreadMessages]: {
    workflowTypes: [CoachWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.Completed]: {
    workflowTypes: coachWorkflowsServedOn
  },
  [CoachDashboardFilters.MemberHealthLog]: {
    workflowTypes: [CoachWorkflowTypes.MemberHealthLog],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.ReEngager]: {
    workflowTypes: [CoachWorkflowTypes.ReEngager],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.MemberHealthLogNoResponse]: {
    workflowTypes: [CoachWorkflowTypes.MemberHealthLog],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.UnreadMessagesNoResponse]: {
    workflowTypes: [CoachWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.ActionPlanCheckIn]: {
    workflowTypes: [CoachWorkflowTypes.ActionPlanCheckIn],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.FirstWeekCheckIn]: {
    workflowTypes: [CoachWorkflowTypes.FirstWeekCheckIn],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachCalls]: {
    workflowTypes: [CoachWorkflowTypes.CoachCall],
    excludeStates: [...commonExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [CoachDashboardFilters.CoachCallsPostCallTasks]: {
    workflowTypes: [CoachWorkflowTypes.CoachCall],
    excludeStates: [...commonExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [CoachDashboardFilters.CoachCallsUpcomingCoachCalls]: {
    workflowTypes: [CoachWorkflowTypes.CoachCall],
    excludeStates: [...commonExcludedStates, VideoVisitsState.PerformVideoVisitFollowup]
  },
  [CoachDashboardFilters.CoachEscalation]: {
    workflowTypes: [CoachWorkflowTypes.CoachEscalation],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.WeeklyEnsoReview]: {
    workflowTypes: [CoachWorkflowTypes.WeeklyEnsoReview],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.OutreachNeeded]: {
    workflowTypes: [CoachWorkflowTypes.OutreachNeeded],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.PregnancyStatusChange]: {
    workflowTypes: [CoachWorkflowTypes.PregnancyStatusChange],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.MaternityMilestones]: {
    workflowTypes: [CoachWorkflowTypes.MaternityMilestones],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.AlertReminder]: {
    workflowTypes: [CoachWorkflowTypes.MemberAlertReminder],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.ReviewAndRecalibrate]: {
    workflowTypes: [CoachWorkflowTypes.ActionPlanReview],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.PainUptick]: {
    workflowTypes: [CoachWorkflowTypes.MemberPainUptick],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.BalanceAssessment]: {
    workflowTypes: [CoachWorkflowTypes.BalanceAssessment],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.FirstWeekReview]: {
    workflowTypes: [CoachWorkflowTypes.FirstWeekGoal],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachWeeklyGoalExpiry]: {
    workflowTypes: [CoachWorkflowTypes.CoachWeeklyGoalExpiry],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal]: {
    workflowTypes: [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachVideoVisit]: {
    workflowTypes: [CoachWorkflowTypes.CoachVideoVisit],
    excludeStates: [...commonExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  },
  [CoachDashboardFilters.CoachVideoVisitIncomplete]: {
    workflowTypes: [CoachWorkflowTypes.CoachVideoVisit],
    excludeStates: [...commonExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [CoachDashboardFilters.CoachVideoVisitUpcoming]: {
    workflowTypes: [CoachWorkflowTypes.CoachVideoVisit],
    excludeStates: [...commonExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  }
});
export const getFilterStatesForPt = (ptWorkflowsServedOn: PtWorkflowTypes[], painUptickWorkflowsPt: PtWorkflowTypes[]): SplitFilteredWorkflowTypes<PtDashboardFilters> => ({
  [PtDashboardFilters.All]: {
    workflowTypes: ptWorkflowsServedOn,
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.UnreadMessage]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.ModularIamUnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.UnreadMessageNoResponse]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.VideoVisit]: {
    workflowTypes: videoVisitWorkflowTypes,
    excludeStates: [...commonPtExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  },
  [PtDashboardFilters.VideoVisitIncomplete]: {
    workflowTypes: videoVisitWorkflowTypes,
    excludeStates: [...commonPtExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [PtDashboardFilters.VideoVisitUpcoming]: {
    workflowTypes: videoVisitWorkflowTypes,
    excludeStates: [...commonPtExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  },
  [PtDashboardFilters.HealthLogNotes]: {
    workflowTypes: [PtWorkflowTypes.HealthLog],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.HealthLogNoResponse]: {
    workflowTypes: [PtWorkflowTypes.HealthLog],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.PainUptick]: {
    workflowTypes: painUptickWorkflowsPt,
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ExpiringCarePlans]: {
    workflowTypes: [PtWorkflowTypes.Expiring],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.DischargeAndClosePlan]: {
    workflowTypes: [PtWorkflowTypes.Closing],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.HouseCall]: {
    workflowTypes: [PtWorkflowTypes.HouseCall],
    excludeStates: [...commonPtExcludedStates, HouseCallState.PerformHouseCall, HouseCallState.PostHouseCallFollowup]
  },
  [PtDashboardFilters.HouseCallIncomplete]: {
    workflowTypes: [PtWorkflowTypes.HouseCall],
    excludeStates: [...commonPtExcludedStates, HouseCallState.PrevisitHouseCall]
  },
  [PtDashboardFilters.HouseCallUpcoming]: {
    workflowTypes: [PtWorkflowTypes.HouseCall],
    excludeStates: [...commonPtExcludedStates, HouseCallState.PerformHouseCall, HouseCallState.PostHouseCallFollowup]
  },
  [PtDashboardFilters.Completed]: {
    workflowTypes: [...Object.values(PtWorkflowTypes)]
  },
  [PtDashboardFilters.SurgeryTrend]: {
    workflowTypes: [PtWorkflowTypes.SurgeryTrend],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.EtModification]: {
    workflowTypes: [PtWorkflowTypes.EtModification],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.IndicationUpdate]: {
    workflowTypes: [PtWorkflowTypes.IndicationUpdate],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ClinicalEscalation]: {
    workflowTypes: [PtWorkflowTypes.ClinicalEscalation],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.MedicalEmergencyFollowUp]: {
    workflowTypes: [PtWorkflowTypes.MedicalEmergencyFollowUp],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.FirstWeekEnsoCheckIn]: {
    workflowTypes: [PtWorkflowTypes.FirstWeekEnsoCheckIn],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.AqRedFlags]: {
    workflowTypes: [PtWorkflowTypes.AqRedFlags],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.PtMemberAlertReminder]: {
    workflowTypes: [PtWorkflowTypes.PtMemberAlertReminder],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ActionPlanReview]: {
    workflowTypes: [PtWorkflowTypes.ActionPlanReview],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.SetupActionPlan]: {
    workflowTypes: [PtWorkflowTypes.SetupActionPlan],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.Messages]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ModularIamUnreadMessages]: {
    workflowTypes: [PtWorkflowTypes.ModularIamUnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.PelvicTrainerOutreach]: {
    workflowTypes: [PtWorkflowTypes.PelvicTrainerOutreach],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.AllTypes]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.ModularIamUnreadMessages],
    excludeStates: commonPtExcludedStates
  }
});
export const getFilterStatesForOs = (oSWorkflowsServedOn: OsWorkflowTypes[]): SplitFilteredWorkflowTypes<OsDashboardFilters> => ({
  [OsDashboardFilters.All]: {
    workflowTypes: oSWorkflowsServedOn,
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.UnreadMessage]: {
    workflowTypes: [OsWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.AlertReminder]: {
    workflowTypes: [OsWorkflowTypes.MemberAlertReminder],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.ReEngager]: {
    workflowTypes: [OsWorkflowTypes.ReEngager],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.OutreachNeeded]: {
    workflowTypes: [OsWorkflowTypes.OutreachNeeded],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.Nuto]: {
    workflowTypes: [OsWorkflowTypes.Nuto],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.Ruto]: {
    workflowTypes: [OsWorkflowTypes.Ruto],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.Completed]: {
    workflowTypes: oSWorkflowsServedOn
  },
  [OsDashboardFilters.UnreadMessageNoResponse]: {
    workflowTypes: [OsWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  }
});
const filterExcludedStatesBySplit = <T extends AllDashboardFilters,>(filterStates: SplitFilteredWorkflowTypes<T>, isEnableWorkflowStateFilteringSplitOn: boolean, commonExcludedStates: string[]): SplitFilteredWorkflowTypes<T> => {
  if (!isEnableWorkflowStateFilteringSplitOn) {
    return filterStates;
  }
  const updatedFilterStates = {
    ...filterStates
  };
  (Object.keys(updatedFilterStates) as Array<T>).forEach(key => {
    const filter = updatedFilterStates[key];
    if (filter?.excludeStates) {
      filter.excludeStates = filter.excludeStates.filter(state => !commonExcludedStates.includes(((state as unknown) as string)));
    }
  });
  return updatedFilterStates;
};