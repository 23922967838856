import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { RemoteParticipantStatus } from '../../../constants/enum';
import { STREAM_VIDEO_CALL_END, TOKEN_NOT_SET } from '../../../constants/video-visit';
import { memberJoinedForTheFirstTimeAtom, memberStatusAtom, streamCallAtom, videoVisitRejoinAtom, videoVisitTokenAtom } from '../../../state/atoms';
export const useStreamCallDisconnect = (): void => {
  const setVideoVisitRejoin = useSetAtom(videoVisitRejoinAtom);
  const setVideoVisitToken = useSetAtom(videoVisitTokenAtom);
  const setMemberJoinedStatus = useSetAtom(memberStatusAtom);
  const setMemberHasJoinedFirstTime = useSetAtom(memberJoinedForTheFirstTimeAtom);
  const [streamCall, setStreamCall] = useAtom(streamCallAtom);
  const resetState = async (): Promise<void> => {
    if (streamCall && streamCall.state.callingState !== 'left') {
      await streamCall.leave();
    }
    setMemberJoinedStatus(RemoteParticipantStatus.NotInTheCall);
    setMemberHasJoinedFirstTime(false);
    setVideoVisitRejoin(false);
    setVideoVisitToken(TOKEN_NOT_SET);
    setStreamCall(null);
  };
  useEffect(() => {
    const handleCallEnd = (): void => parent.window.addEventListener(STREAM_VIDEO_CALL_END, resetState);
    handleCallEnd();
    return () => {
      parent.window.removeEventListener(STREAM_VIDEO_CALL_END, resetState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamCall]);
};