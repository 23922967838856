import { c as _c } from "react-compiler-runtime";
import { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import React, { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { LastSavedMessageOutput } from '../../../hooks/use-last-saved-message';
import { Admin, CarePlanData } from '../../../types';
import { SseEvents } from '../constants/events';
import { validateRouteParam } from '../utils/validate-route-params';
export interface ShellContextType {
  children?: React.ReactNode;
  adminProfile: Admin;
  mixpanelTrack?: (eventName: string, properties?: Dict, optionsOrCallback?: RequestOptions | Callback, callback?: Callback) => void;
  useSse: (url: string, events: SseEvents[], options?: {
    replayLastEvent?: boolean;
  }) => unknown;
  useLastSavedMessage: (storageId?: string) => LastSavedMessageOutput;
  memberId?: string;
  validUrlParams?: boolean;
  genericMixpanelTrack?: <T extends Dict>(eventName: string, properties?: T, optionsOrCallback?: RequestOptions | Callback, callback?: Callback) => void;
}
const initialContext: ShellContextType = {
  useSse: (_url: string, _events: unknown[]) => undefined,
  useLastSavedMessage: (_storageId?: string) => [null, async (): Promise<undefined> => undefined],
  adminProfile: {
    avatarUrl: '',
    carePlanData: ({} as CarePlanData),
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    role: '',
    uuid: 'no-uuid'
  }
};
export const ShellContext = createContext<ShellContextType>(initialContext);
export const ShellProvider = t0 => {
  const $ = _c(13);
  const {
    children,
    adminProfile,
    mixpanelTrack,
    genericMixpanelTrack,
    useSse,
    useLastSavedMessage
  } = t0;
  const {
    id
  } = useParams();
  let t1;
  if ($[0] !== id) {
    t1 = validateRouteParam(id);
    $[0] = id;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const validUrlParams = t1;
  let t2;
  let t3;
  if ($[2] !== adminProfile || $[3] !== mixpanelTrack || $[4] !== genericMixpanelTrack || $[5] !== useSse || $[6] !== useLastSavedMessage || $[7] !== id || $[8] !== validUrlParams) {
    t3 = {
      adminProfile,
      mixpanelTrack,
      genericMixpanelTrack,
      useSse,
      useLastSavedMessage,
      memberId: id,
      validUrlParams
    };
    $[2] = adminProfile;
    $[3] = mixpanelTrack;
    $[4] = genericMixpanelTrack;
    $[5] = useSse;
    $[6] = useLastSavedMessage;
    $[7] = id;
    $[8] = validUrlParams;
    $[9] = t3;
  } else {
    t3 = $[9];
  }
  t2 = t3;
  let t4;
  if ($[10] !== t2 || $[11] !== children) {
    t4 = <ShellContext.Provider value={t2}>{children}</ShellContext.Provider>;
    $[10] = t2;
    $[11] = children;
    $[12] = t4;
  } else {
    t4 = $[12];
  }
  return t4;
};
export const useShellContext = () => {
  return useContext(ShellContext);
};