import { ctpTokens, HHAvatar, HHGlobalNav, HHIconButton, HHItemNavLinkProps, HHPopover, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { AppsRounded } from '@mui/icons-material';
import { useClient } from '@splitsoftware/splitio-react';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NAVIGATION_LINK_NAMES } from '../constants';
import { DROP_SHADOW } from '../constants/colors/colors';
import { NAV_SPLIT_TREATMENTS, ON } from '../constants/strings/split';
import { useCurrentStoredAdminHook } from '../hooks/use-current-stored-admin';
import { SEARCH_MIXPANEL_EVENTS } from '../modules/member-search/constants/strings/member-search';
import { useShellContext } from '../modules/sidebar/contexts/shell.context';
import { rolesAtom } from '../state/queue-atoms';
import { OktaRole } from '../types/okta-roles';
import { OktaLocalStorageKeys } from '../types/types';
import { mixpanelTrack } from '../utils/mixpanel';
import { getRoleBasedNavItems } from '../utils/role-based-navigation-items';
import { QuickLinks } from './quick-links';
import { QueueCategoryAlert } from './workflows-queue-components/queue-category-alert';
const styles = {
  // this will get removed when CTT gets sunsetted
  navIconLayout: {
    color: ctpTokens.palette.neutral[100]
  },
  navIcon: {
    padding: 0
  },
  drawerPaper: {
    marginLeft: 14,
    boxShadow: DROP_SHADOW
  }
};
export const MIXPANEL_TIMESTAMP_FORMAT = 'ddd, MMM DD, YYYY hh:mm A z';
const SideNavigationDefault = (): JSX.Element => {
  const splitClient = useClient();
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const {
    adminProfile
  } = useShellContext();
  const {
    hingeHealthAuthClient,
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const setRoles = useSetAtom(rolesAtom);
  const adminRoles = hingeHealthAuthState?.accessToken?.claims.roles;
  useEffect(() => {
    if (adminRoles) {
      setRoles(adminRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hingeHealthAuthState]);
  const redirectCttToCtpTreatment = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.REDIRECT_CTT_TO_CTP, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON;
  const allOktaKeys = Object.values(OktaLocalStorageKeys);
  const currentLocation = useLocation().pathname;
  const navigationItems = useMemo(() => getRoleBasedNavItems((adminRoles as OktaRole[]), false, false, false, redirectCttToCtpTreatment), [adminRoles, redirectCttToCtpTreatment]);
  const [showQuickLinksPopover, setShowQuickLinksPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const mixpanelProperties = {
    adminId: adminProfile?.uuid,
    role: adminProfile?.role,
    timestamp: dayjs().tz(dayjs.tz.guess()).format(MIXPANEL_TIMESTAMP_FORMAT)
  };
  const invokeMixpanelEvent = async (event: string, eventProperties?: object): Promise<void> => {
    mixpanelTrack?.(event, {
      ...mixpanelProperties,
      ...eventProperties
    });
  };
  const navLinkClickHandlers = {
    [NAVIGATION_LINK_NAMES.MEMBER_SEARCH]: (): void => {
      invokeMixpanelEvent(SEARCH_MIXPANEL_EVENTS.CLICK_MEMBER_SEARCH);
    }
  };
  const addClickHandler = (navLink: HHItemNavLinkProps): HHItemNavLinkProps => {
    const clickHandlerFn = navLinkClickHandlers[navLink.primary];
    const clickHandler = (): void => {
      clickHandlerFn && clickHandlerFn();
    };
    const icon = navLink.icon;
    return {
      ...navLink,
      icon: <HHIconButton hhVariant={'variant-bypass'} sx={{
        ...styles.navIconLayout,
        ...styles.navIcon
      }} onClick={clickHandler}>
          {icon}
        </HHIconButton>
    };
  };
  const navigationItemsWithHandlers = useMemo(() => navigationItems.map(addClickHandler),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [navigationItems]);
  const handleLogout = async (): Promise<void> => {
    try {
      await hingeHealthAuthClient.signOut();
    } finally {
      allOktaKeys.forEach((oktaKey: string) => localStorage.removeItem(oktaKey));
    }
  };
  const showQuickLinks = (event_0: React.MouseEvent<HTMLElement>): void => {
    event_0.preventDefault();
    setAnchorEl(event_0?.currentTarget);
    setShowQuickLinksPopover(true);
  };
  const handleClose = (event_1: React.MouseEvent<HTMLElement>): void => {
    event_1.preventDefault();
    setShowQuickLinksPopover(false);
    setAnchorEl(null);
  };
  return <>
      <HHGlobalNav navigationItems={navigationItemsWithHandlers} footerItems={[{
      to: currentLocation,
      primary: '',
      icon: <>
                <HHIconButton hhVariant={'variant-bypass'} onClick={showQuickLinks} aria-label="quick-links" sx={{
          color: 'white',
          ...styles.navIcon
        }}>
                  <AppsRounded />
                </HHIconButton>
                <HHPopover anchorEl={anchorEl} open={showQuickLinksPopover} onClose={handleClose} children={<QuickLinks setShowQuickLinksPopover={setShowQuickLinksPopover} />} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }} transformOrigin={{
          vertical: 76,
          horizontal: 30
        }} />
              </>
    }, {
      to: currentLocation,
      primary: 'Logout',
      icon: <HHIconButton hhVariant={'variant-bypass'} onClick={handleLogout} aria-label="Avatar logout button" sx={styles.navIcon}>
                <HHAvatar hhVariant="small">A</HHAvatar>
              </HHIconButton>
    }]} />
      <QueueCategoryAlert />
    </>;
};
export default SideNavigationDefault;