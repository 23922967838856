import { c as _c } from "react-compiler-runtime";
import { HHButton, HHDialog, HHDialogContent, HHDialogTitle, HHLink, HHTypography } from '@hinge-health/react-component-library';
import { Close, Done, InfoOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { useCurrentStoredAdminHook } from '../../../hooks/use-current-stored-admin';
import { currentUserAtom } from '../../../modules/member-360/state/atoms';
import { defaultErrorHandler } from '../../../utils/errors';
import { freshdeskCreateTicket, uploadTicketAttachments, validateTicketAttachment } from '../../../utils/freshdesk-dialog-utils';
import { FreshdeskSubmissionForm } from './freshdesk-dialog-form';
import { layoutStyles, sxStyles } from './freshdesk-dialog-styles';
export enum TicketPriority {
  Medium = 2,
  High = 3,
}
enum TicketStatus {
  New = 1,
  Open = 2,
  Pending = 3,
  Resolved = 4,
  Closed = 5,
  OnHold = 6,
}
export interface FreshdeskTicketDetails {
  subject: string;
  description: string;
  email: string;
  priority: TicketPriority;
  status: TicketStatus;
}
export interface FreshdeskTicketCustomFields {
  cf_user_id?: string | number; // User ID: One user per ticket (No user ID? Enter 0000) from freshdesk UI
  cf_program?: string; // Program from freshdesk UI
  cf_pathway?: string; // Indication from freshdesk UI
  cf_internal_requester_type?: string; // What is your role? from freshdesk UI
  cf_request_type_new?: string; // What kind of support do you need? from freshdesk UI
  cf_detailed_summary?: string; // Details (What did you expect to happen? What actually happened? When did it happen?) from freshdesk UI
  cf_date_of_issue?: string; // Date of issue from freshdesk UI
}
interface FreshdeskDialogContentProps {
  isOpen: boolean;
  toggleFunction: (value: boolean) => void;
}
const ButtonSubmitAnother = t0 => {
  const $ = _c(6);
  let props;
  if ($[0] !== t0) {
    ({
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
  } else {
    props = $[1];
  }
  let t1;
  if ($[2] !== props) {
    t1 = () => {
      props.setFeedbackSubmitted(false);
      props.setIsError(false);
      props.setErrorMessage("");
    };
    $[2] = props;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  let t2;
  if ($[4] !== t1) {
    t2 = <HHButton hhVariant="text" fullWidth={true} layoutStyles={layoutStyles.typography} onClick={t1}>{USER_FEEDBACK.SUBMIT_ANOTHER_BUTTON_TEXT}</HHButton>;
    $[4] = t1;
    $[5] = t2;
  } else {
    t2 = $[5];
  }
  return t2;
};
export const FreshdeskDialog = ({
  toggleFunction,
  isOpen
}: FreshdeskDialogContentProps): JSX.Element => {
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  const [freshdeskTicketId, setFreshdeskTicketId] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderMessage, setLoaderMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [fileUploadError, setFileUploadError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: ''
  });
  const [titleError, setTitleError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const currentUser = useAtomValue(currentUserAtom);
  const [freshdeskTicketDetails, setFeedbackTicketDetails] = useState<FreshdeskTicketDetails>({
    subject: '',
    description: '',
    email: currentStoredAdmin?.email || '',
    priority: TicketPriority.Medium,
    status: TicketStatus.Open
  });
  const [freshdeskTicketCustomFields, setFreshdeskTicketCustomFields] = useState<FreshdeskTicketCustomFields>({
    cf_user_id: currentUser.id || '',
    cf_program: '',
    cf_pathway: '',
    cf_internal_requester_type: '',
    cf_request_type_new: '',
    cf_detailed_summary: '',
    cf_date_of_issue: ''
  });
  const [feedbackFiles, setFeedbackFiles] = useState<FileList | null>(null);
  const [fileUploadSizeError, setFileUploadSizeError] = useState('');
  const [chipstatus, setChipstatus] = useState<string>(USER_FEEDBACK.BUG_STRING);

  // Function to handle the file upload
  const handleFeedbackFilesUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const invalidFile = filesArray.find(file => file.size > USER_FEEDBACK.MAX_FILE_SIZE_BYTES);
      if (invalidFile) {
        setFileUploadSizeError(USER_FEEDBACK.UPLOAD_ERROR_MESSAGE);
        return;
      }
      if (filesArray.length <= USER_FEEDBACK.MAX_NUMBER_OF_FILES_UPLOAD) {
        setFeedbackFiles(prevFiles => {
          const newFiles = e.target.files;
          if (prevFiles && newFiles && prevFiles.length + newFiles.length <= USER_FEEDBACK.MAX_NUMBER_OF_FILES_UPLOAD) {
            const fileList = ((Array.from(((prevFiles as unknown) as File[])).concat(Array.from(((newFiles as unknown) as File[]))) as unknown) as FileList);
            return fileList;
          }
          return newFiles;
        });
      }
    }
  };
  const handleDeleteItem = (index: number): void => {
    if (feedbackFiles) {
      const files = Array.from(((feedbackFiles as unknown) as File[]));
      files.splice(index, 1);
      const fileList_0 = ((files as unknown) as FileList);
      setFeedbackFiles(fileList_0);
    }
  };
  const handleSubmitFeedback = async (): Promise<void> => {
    setLoader(true);
    setIsError(false);

    //validation here
    if (!freshdeskTicketDetails.subject) {
      setTitleError(USER_FEEDBACK.ERROR_TEXT_VALUE);
      setLoader(false);
      return;
    }
    if (!freshdeskTicketDetails.description) {
      setDescriptionError(USER_FEEDBACK.ERROR_TEXT_VALUE);
      setLoader(false);
      return;
    }
    if (feedbackFiles?.length) {
      const validateFiles = validateTicketAttachment(feedbackFiles);
      if (!validateFiles.status) {
        setIsError(true);
        setErrorMessage(validateFiles.message);
        setLoader(false);
        return;
      }
    }
    try {
      // Create ticket to freshdesk
      setLoaderMessage(USER_FEEDBACK.LOADER_TEXT.CREATING_TICKET);
      const createTicket = (await freshdeskCreateTicket({
        ticketDetails: freshdeskTicketDetails,
        customTicketDetails: freshdeskTicketCustomFields,
        chipStatus: chipstatus
      }) as {
        data: {
          id: string;
        };
        status: number;
        message: string;
      });
      if (!createTicket.status) {
        setIsError(true);
        setErrorMessage(createTicket.message || USER_FEEDBACK.FEEDBACK_NETWORK_ERROR);
        defaultErrorHandler(createTicket.message);
        return;
      }
      setFreshdeskTicketId(createTicket?.data?.id);
      if (createTicket?.data?.id && feedbackFiles?.length) {
        setLoaderMessage(USER_FEEDBACK.LOADER_TEXT.UPLOADING_FILES);
        const uploadResponse = await uploadTicketAttachments(createTicket?.data?.id, feedbackFiles);
        if (!uploadResponse?.status) {
          setFileUploadError({
            isError: true,
            message: uploadResponse?.data?.message || USER_FEEDBACK.FEEDBACK_NETWORK_ERROR
          });
          defaultErrorHandler(uploadResponse?.data?.message);
        }
      }
      setFeedbackSubmitted(true);
      clearStateFromLocalStorage();
      setFreshdeskTicketCustomFields({
        cf_pathway: '',
        cf_program: ''
      });
      setFeedbackTicketDetails({
        subject: '',
        description: '',
        email: currentStoredAdmin?.email || '',
        priority: TicketPriority.Medium,
        status: TicketStatus.Open
      });
    } catch (error) {
      setIsError(true);
      setErrorMessage(USER_FEEDBACK.FEEDBACK_SUBMIT_ERROR);
    } finally {
      setLoader(false);
      setLoaderMessage('');
      setFeedbackFiles(null);
      setFeedbackTicketDetails({
        ...freshdeskTicketDetails,
        subject: '',
        description: ''
      });
    }
  };
  const handleTicketLinkClick = (ticketId: string = freshdeskTicketId): void => {
    window.open(USER_FEEDBACK.FRESHDESK_TICKET_URL + ticketId, '_blank');
  };
  useEffect(() => {
    const savedCustomFields = localStorage.getItem(USER_FEEDBACK.LOCAL_STORAGE_KEY_NAMES.FRESHDESK_TICKET_CUSTOM_FIELDS);
    const savedTicketDetails = localStorage.getItem(USER_FEEDBACK.LOCAL_STORAGE_KEY_NAMES.FRESHDESK_TICKET_FIELDS);
    if (savedCustomFields) {
      setFreshdeskTicketCustomFields(JSON.parse(savedCustomFields));
    }
    if (savedTicketDetails) {
      setFeedbackTicketDetails(JSON.parse(savedTicketDetails));
    }
  }, []);
  const saveStateToLocalStorage = (): void => {
    localStorage.setItem(USER_FEEDBACK.LOCAL_STORAGE_KEY_NAMES.FRESHDESK_TICKET_CUSTOM_FIELDS, JSON.stringify(freshdeskTicketCustomFields));
    localStorage.setItem(USER_FEEDBACK.LOCAL_STORAGE_KEY_NAMES.FRESHDESK_TICKET_FIELDS, JSON.stringify(freshdeskTicketDetails));
  };
  const clearStateFromLocalStorage = (): void => {
    localStorage.removeItem(USER_FEEDBACK.LOCAL_STORAGE_KEY_NAMES.FRESHDESK_TICKET_CUSTOM_FIELDS);
    localStorage.removeItem(USER_FEEDBACK.LOCAL_STORAGE_KEY_NAMES.FRESHDESK_TICKET_FIELDS);
  };
  const handleClose = (): void => {
    saveStateToLocalStorage();
    toggleFunction(!isOpen);
  };
  return <HHDialog open={isOpen} sx={{
    padding: '5px'
  }} PaperProps={{
    sx: sxStyles.hhDialogPaperProps
  }}>
      <HHDialogTitle sx={{
      padding: '7px 22px 1px 26px'
    }}>
        {!loader && !feedbackSubmitted && !isError && USER_FEEDBACK.DIALOG_TEXTS.FROM_TITLE}
        <Close data-testid={USER_FEEDBACK.TEST_IDS.CLOSE_BUTTON} onClick={handleClose} sx={sxStyles.closeButton} />
      </HHDialogTitle>
      {/* Loader component */}
      {loader && <HHDialogContent>
          <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 12,
        width: '100%'
      }}>
            <CircularProgress />
          </Box>
          <HHTypography hhVariant={'h2'} layoutStyles={layoutStyles.typography}>
            {loaderMessage}
          </HHTypography>
        </HHDialogContent>}
      {/* Submit alert component */}
      {!loader && feedbackSubmitted && <HHDialogContent sx={{
      textAlign: 'center'
    }}>
          <Box sx={sxStyles.doneIconBox}>
            <Done sx={sxStyles.doneIcon} />
          </Box>
          <HHTypography hhVariant={'section-title'} layoutStyles={layoutStyles.typography}>
            {USER_FEEDBACK.SUCCESS_TITLE}
          </HHTypography>
          {USER_FEEDBACK.SUCCESS_ALERT_PREFIX}
          <HHLink onClick={(e_0): void => {
        e_0.preventDefault();
        handleTicketLinkClick(freshdeskTicketId);
      }} href={USER_FEEDBACK.FRESHDESK_TICKET_URL + freshdeskTicketId}>
            {freshdeskTicketId}
          </HHLink>
          {USER_FEEDBACK.SUCCESS_ALERT_SUFFIX}
          {fileUploadError.isError && <HHTypography hhVariant={'body1'} layoutStyles={layoutStyles.typography}>
              {fileUploadError.message}
            </HHTypography>}
          <ButtonSubmitAnother setFeedbackSubmitted={setFeedbackSubmitted} setIsError={setIsError} setErrorMessage={setIsError} />
        </HHDialogContent>}
      {/* Error Component */}
      {isError && errorMessage && errorMessage.length && <HHDialogContent sx={{
      textAlign: 'center'
    }}>
          <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px'
      }}>
            <InfoOutlined sx={{
          background: 'rgba(252, 225, 222, 1)',
          color: 'rgba(207, 49, 43, 1)'
        }} />
          </Box>
          <HHTypography hhVariant={'section-title'} layoutStyles={layoutStyles.typography}>
            {USER_FEEDBACK.FAILURE_TITLE}
          </HHTypography>
          {errorMessage}
          <ButtonSubmitAnother setFeedbackSubmitted={setFeedbackSubmitted} setIsError={setIsError} setErrorMessage={setIsError} />
        </HHDialogContent>}
      {!loader && !feedbackSubmitted && !isError && <FreshdeskSubmissionForm freshdeskTicketDetails={freshdeskTicketDetails} customFields={freshdeskTicketCustomFields} descriptionError={descriptionError} feedbackFiles={feedbackFiles} layoutStyles={layoutStyles} titleError={titleError} sxStyles={sxStyles} loader={loader} fileUploadSizeError={fileUploadSizeError} updateTicketData={setFeedbackTicketDetails} handleFileUpload={handleFeedbackFilesUpload} handleChipChange={setChipstatus} updateCustomFields={setFreshdeskTicketCustomFields} handleDeleteItem={handleDeleteItem} handleSubmit={handleSubmitFeedback} />}
    </HHDialog>;
};