import { createContext } from 'react';
interface FeatureFlags {
  [key: string]: boolean | string | null;
}

/**
 * FeatureFlagContext provides access to feature flags throughout the application.
 *
 * Usage:
 *
 * 1. Wrap your component tree with FeatureFlagProvider:
 *
 * <FeatureFlagProvider>
 *   <YourComponent />
 * </FeatureFlagProvider>
 *
 * 2. Access feature flags using the useContext hook:
 *
 * import { useContext } from 'react';
 * import { FeatureFlagContext } from 'path-to-feature-flag-context';
 *
 * const { featureFlags } = useContext(FeatureFlagContext);
 *
 * if (featureFlags?.flag1 === 'on') {
 *   // Feature flag 'flag1' is enabled
 * }
 *
 */

export const FeatureFlagContext = createContext<{
  featureFlags: FeatureFlags | null;
}>({
  featureFlags: null
});