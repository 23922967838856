import { ctpTokens, HHBox, HHButton, HHChip, HHDatePicker, HHDialogActions, HHDialogContent, HHDivider, HHList, HHListItem, HHSelect, HHSelectChangeEvent, HHSelectOption, HHStack, HHTextField, HHTypography, Typography } from '@hinge-health/react-component-library';
import { DeleteOutlineRounded, DoneRounded, InfoOutlined, UploadFileSharp } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { FRESHDESK_FIELDS, USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { currentUserAtom } from '../../../modules/member-360/state/atoms';
import { FeedbackMessage, FeedbackType } from '../../../utils/freshdesk-dialog-utils';
import { FreshdeskTicketCustomFields, FreshdeskTicketDetails, TicketPriority } from './freshdesk-dialog';
import { layoutStyles } from './freshdesk-dialog-styles';
export const programSelectInputId = 'program-select';
export const indicationSelectInputId = 'indication-select';
export const formatTicketDate = (date: Date | null): string => date && dayjs(date).isValid() ? dayjs(date).startOf('day').format('YYYY-MM-DD') : '';
export const handleDateChange = (date: Date | null, setDateValue: (value: string) => void, setDateError: (value: boolean) => void, updateCustomFields: (callback: (prevData: FreshdeskTicketCustomFields) => FreshdeskTicketCustomFields) => void): void => {
  const validDate = formatTicketDate(date);
  setDateValue(validDate);
  setDateError(!validDate);
  updateCustomFields((prevData: FreshdeskTicketCustomFields) => ({
    ...prevData,
    cf_date_of_issue: validDate
  }));
};
export const FreshdeskSubmissionForm = ({
  ...props
}): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [typeChip, setTypeChip] = useState<string>(USER_FEEDBACK.BUG_STRING);
  const [role, setRole] = useState<string>(props.customFields.cf_internal_requester_type);
  const [request, setRequest] = useState<string>(props.customFields.cf_request_type_new);
  const [roleError, setRoleError] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<string>(props.customFields.cf_date_of_issue);
  const [dateError, setDateError] = useState<boolean>(false);
  const [priorityValue, setPriorityValue] = useState<string>('');
  const [memberSelectionOption, setMemberSelectionOption] = useState<string>(USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.ALL.toUpperCase());
  const [selectedProgram, setSelectedProgram] = useState<string>('');
  const [selectedIndication, setSelectedIndication] = useState<string>('');
  const currentUser = useAtomValue(currentUserAtom);
  const [userIds, setUserIds] = useState('');
  const [userId, setUserId] = useState(currentUser?.id || '');
  const validateForm = (): boolean => {
    const isTitleValid = !!props.freshdeskTicketDetails.subject;
    const isDescriptionValid = !!props.freshdeskTicketDetails.description;
    const isRoleValid = !!role;
    const isRequestValid = !!request;
    setRoleError(!isRoleValid);
    setRequestError(!isRequestValid);
    setTitleError(!isTitleValid);
    setDescriptionError(!isDescriptionValid);
    return isTitleValid && isDescriptionValid && isRoleValid && isRequestValid && !dateError;
  };
  const handleSubmit = (): void => {
    if (validateForm()) {
      props.handleSubmit();
    }
  };
  // Function to trigger file input click
  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the file input
    }
  };
  const handleToggleBugFeatureChip = (type: string): void => {
    setTypeChip(type);
    props.handleChipChange(type);
  };
  const memberImpactedOptions: HHSelectOption[] = Object.entries(USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED).map(([key, value]) => ({
    displayName: value,
    value: key
  }));
  const handlePriorityChange = (e: HHSelectChangeEvent): void => {
    const value_0 = (e.target.value as string);
    setPriorityValue(value_0);
    props.updateTicketData((prevData: FreshdeskTicketDetails) => ({
      ...prevData,
      priority: value_0 === FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.PRIORITY_OPTIONAL[1] ? TicketPriority.High : TicketPriority.Medium
    }));
  };
  const singleUserIdChange = (e_0: React.ChangeEvent<HTMLInputElement>): void => {
    const value_1 = e_0.target.value;
    const regex = /^\d*$/;
    if (regex.test(value_1)) {
      setUserId(value_1);
      props.updateCustomFields((prevData_0: FeedbackMessage) => ({
        ...prevData_0,
        cf_user_id: value_1 ?? currentUser.id
      }));
    }
  };
  const handleMultipleUserIdsChange = (e_1: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e_1.target.value.trim();
    const regex_0 = /^[0-9, ]+$/;
    if (regex_0.test(input)) {
      setUserIds(e_1.target.value);
      const userIds_0 = input.split(',').map(id => id.trim());
      props.updateCustomFields((prevData_1: FeedbackMessage) => ({
        ...prevData_1,
        cf_user_id: userIds_0
      }));
    }
  };
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const pathways = currentUser?.pathways;
    if (pathways && pathways.length) {
      const CurrentPathway = pathways.find(pathway => pathway.isActive === true) || pathways[0];
      // setting pathway and program
      const pathway_0 = CurrentPathway.indication;
      const program = CurrentPathway.type.charAt(0).toUpperCase() + CurrentPathway.type.slice(1).toLocaleLowerCase();
      setSelectedProgram(program || '');
      // map proper pathway
      const pathwayFromPredefinedFields = FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PATHWAY.find(pathwayOption => pathwayOption.toLowerCase().includes(pathway_0.toLowerCase()));
      setSelectedIndication(pathwayFromPredefinedFields || '');
      props.updateCustomFields((prevData_2: FeedbackMessage) => ({
        ...prevData_2,
        cf_pathway: pathwayFromPredefinedFields,
        cf_program: program,
        cf_user_id: currentUser.id
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  return <HHDialogContent sx={{
    padding: '0px 24px !important'
  }}>
      <HHTypography hhVariant="h4" layoutStyles={{
      padding: '15px 9px 0px 2px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.ABOUT_YOU}
      </HHTypography>
      <HHStack direction={'row'} spacing={2} sx={{
      padding: '12px 1px 12px 0px',
      width: '100%'
    }}>
        <HHSelect initialSelectValue={role} label={USER_FEEDBACK.DIALOG_TEXTS.ROLE} onChange={(e_2: HHSelectChangeEvent): void => {
        const role_0 = (e_2.target.value as string);
        setRole(role_0);
        setRoleError(false);
        props.updateCustomFields((prevData_3: FreshdeskTicketCustomFields) => ({
          ...prevData_3,
          cf_internal_requester_type: role_0
        }));
      }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_INTERNAL_REQUESTER_TYPE.map(option => ({
        value: option,
        label: option,
        displayName: option
      }))} size={'small'} width={'50%'} error={roleError} helperText={roleError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />
        <HHSelect initialSelectValue={request} label={USER_FEEDBACK.DIALOG_TEXTS.SUPPORT} onChange={(e_3: HHSelectChangeEvent): void => {
        const request_0 = (e_3.target.value as string);
        setRequest(request_0);
        setRequestError(false);
        props.updateCustomFields((prevData_4: FreshdeskTicketCustomFields) => ({
          ...prevData_4,
          cf_request_type_new: request_0
        }));
      }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_REQUEST_TYPE_NEW.map(option_0 => ({
        value: option_0,
        label: option_0,
        displayName: option_0
      }))} size={'small'} width={'50%'} error={requestError} helperText={requestError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />
      </HHStack>
      <HHTypography hhVariant="h4" layoutStyles={{
      paddingBottom: '13px',
      paddingTop: '8px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED_TITLE}
      </HHTypography>
      <HHBox sx={{
      paddingBottom: '15px'
    }}>
        <HHSelect onChange={(e_4: HHSelectChangeEvent): void => {
        setMemberSelectionOption(String(e_4.target.value));
      }} id="member-impacted-select" initialSelectValue={memberSelectionOption} selectOptions={memberImpactedOptions} size="small" sx={{
        width: '592px',
        marginBottom: '7px'
      }} />
        {memberSelectionOption === USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.SINGLE.toUpperCase() && <HHStack direction="row" spacing={2} sx={{
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 1
      }}>
            <HHTextField label={USER_FEEDBACK.USER_ID} onChange={singleUserIdChange} hhVariant="outlined" variant="standard" value={userId} />

            <HHSelect label={USER_FEEDBACK.PROGRAM} id={programSelectInputId} initialSelectValue={selectedProgram} onChange={(e_5: HHSelectChangeEvent): void => {
          const value_2 = e_5.target.value;
          setSelectedProgram(value_2.toString());
          props.updateCustomFields((prevData_5: FeedbackMessage) => ({
            ...prevData_5,
            cf_program: value_2
          }));
        }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PROGRAM.map(option_1 => ({
          value: option_1,
          label: option_1,
          displayName: option_1
        }))} width={'33%'} />

            <HHSelect label={USER_FEEDBACK.INDICATION_LABEL} initialSelectValue={selectedIndication} id={indicationSelectInputId} onChange={(e_6: HHSelectChangeEvent): void => {
          const value_3 = e_6.target.value;
          setSelectedIndication(value_3.toString());
          props.updateCustomFields((prevData_6: FeedbackMessage) => ({
            ...prevData_6,
            cf_pathway: value_3
          }));
        }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PATHWAY.map(option_2 => ({
          value: option_2,
          label: option_2,
          displayName: option_2
        }))} width={'33%'} />
          </HHStack>}

        {memberSelectionOption === USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.MULTIPLE.toUpperCase() && <HHStack direction="column" spacing={2} mt={2}>
            <HHTypography hhVariant="h5">
              User IDs (comma separated)
            </HHTypography>
            <HHTextField label={USER_FEEDBACK.MULTIPLE_USER_ID} hhVariant="outlined" variant="standard" onChange={handleMultipleUserIdsChange} placeholder={USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_PLACEHOLDER} value={userIds} />
          </HHStack>}
      </HHBox>
      <HHTypography hhVariant="h4" layoutStyles={{
      paddingBottom: '15px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_REQUEST_QUES}{' '}
      </HHTypography>
      <HHChip clickable color={typeChip === USER_FEEDBACK.BUG_STRING ? 'secondary' : 'default'} hhVariant={'filled'} label={USER_FEEDBACK.BUG_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.BUG_STRING.slice(1).toLocaleLowerCase()} onClick={(): void => {
      handleToggleBugFeatureChip(USER_FEEDBACK.BUG_STRING);
    }} />
      <HHChip clickable hhVariant={'filled'} color={typeChip === USER_FEEDBACK.REQUEST_STRING ? 'secondary' : 'default'} layoutStyles={{
      marginLeft: '10px'
    }} label={USER_FEEDBACK.REQUEST_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.REQUEST_STRING.slice(1).toLocaleLowerCase()} onClick={(): void => {
      handleToggleBugFeatureChip(USER_FEEDBACK.REQUEST_STRING);
    }} />
      <HHBox sx={{
      padding: '10px 2px 16px 2px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    }}>
        <HHTextField hhVariant="outlined" label={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].TITLE_PLACEHOLDER} InputProps={{
        minRows: 1,
        className: 'title-input'
      }} placeholder={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].TITLE_PLACEHOLDER} size={'small'} defaultValue={props.freshdeskTicketDetails.subject} fullWidth sx={{
        width: '100%'
      }} onChange={(e_7): void => {
        setTitleError(false);
        props.updateTicketData((prevData_7: FreshdeskTicketDetails) => ({
          ...prevData_7,
          subject: e_7.target.value.trim()
        }));
      }} error={titleError} helperText={titleError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />

        <HHTextField hhVariant="outlined" label={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].DESCRIPTION_PLACEHOLDER} multiline fullWidth rows={4} placeholder={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].DESCRIPTION_PLACEHOLDER} size={'medium'} defaultValue={props.freshdeskTicketDetails.description} sx={{
        width: '100%'
      }} onChange={(e_8): void => {
        setDescriptionError(false);
        props.updateTicketData((prevData_8: FreshdeskTicketDetails) => ({
          ...prevData_8,
          description: e_8.target.value.trim()
        }));
      }} error={descriptionError} helperText={descriptionError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />
      </HHBox>
      <HHBox sx={{
      padding: '0px 2px 15px 2px',
      display: 'flex',
      gap: '16px',
      flexWrap: 'wrap'
    }}>
        <HHStack direction={'row'} spacing={2} sx={{
        width: '100%',
        justifyContent: 'space-between'
      }}>
          <HHBox width={'50%'}>
            <HHDatePicker onChange={(date: Date | null): void => handleDateChange(date, setDateValue, setDateError, props.updateCustomFields)} value={dateValue ? dayjs(dateValue).toDate() : null} label={USER_FEEDBACK.DIALOG_TEXTS.BUG.DATE_OF_ISSUE} disableFuture renderInput={(params): JSX.Element => <HHTextField hhVariant="outlined" {...params} size="small" fullWidth error={dateError} helperText={dateError ? USER_FEEDBACK.DATE_ERROR : ''} />} PopperProps={{
            style: {
              width: '320px'
            },
            placement: 'bottom-start'
          }} />
          </HHBox>
          <HHStack width={'50%'}>
            <HHSelect initialSelectValue={priorityValue} label={USER_FEEDBACK.PRIORITY_TITLE} id={USER_FEEDBACK.PRIORITY_ID} onChange={handlePriorityChange} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.PRIORITY_OPTIONAL.map(option_3 => ({
            value: option_3,
            label: option_3,
            displayName: option_3
          }))} MenuProps={{
            PaperProps: {
              style: {
                width: '270px'
              }
            },
            sx: {
              '& .MuiMenuItem-root': {
                whiteSpace: 'normal',
                lineHeight: '1.2'
              }
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }} />
          </HHStack>
        </HHStack>
      </HHBox>
      <HHTypography hhVariant="h4">
        {USER_FEEDBACK.DIALOG_TEXTS.ATTACHMENT_TEXT}
      </HHTypography>
      <input data-testid={USER_FEEDBACK.TEST_IDS.FILE_INPUT} type={'file'} accept={'image/*, video/*, .har, .doc, .docx, .pdf'} multiple ref={fileInputRef} style={{
      display: 'none'
    }} onClick={(e_9): void => e_9.stopPropagation()} onChange={props.handleFileUpload} />
      <HHButton hhVariant={'outlined'} layoutStyles={{
      marginTop: '8px',
      marginBottom: '5px',
      lineHeight: '26px'
    }} onClick={handleButtonClick}>
        <UploadFileSharp sx={{
        marginRight: '8px',
        fontSize: '13px'
      }} />{' '}
        {USER_FEEDBACK.UPLOAD_FILES_TEXT}
      </HHButton>
      {props.fileUploadSizeError && <HHBox sx={layoutStyles.fileUploadErrorBox}>
          {props.fileUploadSizeError}
        </HHBox>}
      <br />
      <Box sx={props.sxStyles.phiDisclaimerBox}>
        <Stack direction={'row'} spacing={2}>
          <InfoOutlined sx={{
          color: 'rgba(103, 111, 109, 1)'
        }} />{' '}
          <Typography sx={props.sxStyles.phiDisclaimerTypography} align={'center'}>
            {USER_FEEDBACK.DISCLAIMER_TEXT}
          </Typography>
        </Stack>
      </Box>

      {/* Uploaded files will show here */}
      {props.feedbackFiles && props.feedbackFiles.length > 0 && <HHList>
          {Array.from((props.feedbackFiles as File[])).map((file: File, index: number) => <HHListItem key={file.name} sx={props.sxStyles.hhListItem}>
                <Stack direction={'row'} spacing={'12px'} alignItems="center" sx={props.sxStyles.stack}>
                  <DoneRounded sx={{
            color: 'rgba(0, 0, 0, 0.54)'
          }} />
                  <Typography variant="body1" sx={{
            flex: 1
          }}>
                    {file.name}
                  </Typography>
                  <DeleteOutlineRounded data-testid={USER_FEEDBACK.TEST_IDS.DELETE_BUTTON + index} sx={{
            marginLeft: '100px',
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'pointer'
          }} onClick={(): void => props.handleDeleteItem(index)} />
                </Stack>
              </HHListItem>)}
        </HHList>}
      <HHDialogActions sx={{
      ...props.sxStyles.hhDialogActions,
      justifyContent: 'space-between',
      backgroundColor: 'white',
      width: '100%',
      zIndex: 50
    }}>
        <HHStack width={'100%'} rowGap={4}>
          <HHDivider sx={props.sxStyles.divider} />
          <HHStack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <HHTypography sx={{
            fontSize: '14px'
          }} hhVariant="variant-bypass" color={ctpTokens.palette.neutral[10]}>
              Review
              <button style={props.sxStyles.baseUrl} onClick={(e_10): void => {
              e_10.preventDefault();
              window.open(USER_FEEDBACK.KNOWLEDGE_BASE_URL, '_blank', 'noopener noreferrer');
            }}>
                Knowledge Base URL
              </button>
              before submitting
            </HHTypography>
            <HHButton sx={props.sxStyles.submitButton} hhVariant="variant-bypass" onClick={handleSubmit} fullWidth={false} disabled={props.loader}>
              {USER_FEEDBACK.SUBMIT_FORM_BUTTON_TEXT}
            </HHButton>
          </HHStack>
        </HHStack>
      </HHDialogActions>
    </HHDialogContent>;
};