import { c as _c } from "react-compiler-runtime";
import { useClient } from '@splitsoftware/splitio-react';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { ON, SPLIT_TREATMENTS } from '../../../../constants/splits';
import { PANEL_CATEGORY } from '../../../../constants/strings/coach-layout';
import { OS_PANEL_CATEGORY } from '../../../../constants/strings/os-layout';
import { PT_PANEL_CATEGORY } from '../../../../constants/strings/pt-layout';
import { useShellContext } from '../../../../contexts/shell.context';
import { DashboardType } from '../../../../custom-types';
import { CoachDashboardFilters } from '../../../../types/coach';
import { OsDashboardFilters } from '../../../../types/os';
import { PtDashboardFilters } from '../../../../types/pt';
interface FilterCategory<T> {
  name: Array<T>;
  category?: string;
  id: string;
  showDivider?: boolean;
  showCategoryHeader?: boolean;
}
export const useGetFilterCategories = dashboardType => {
  const $ = _c(51);
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const healthLogNotesTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const setupActionPlanTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const isPtActionPlanReviewWorkflowSplitOn = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachWeeklyGoalExpiry = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachMemberUpdatedWeeklyGoal = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachVideoVisitSplitOn = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_VIDEO_VISIT, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  let t0;
  bb0: {
    if (dashboardType === DashboardType.Coach) {
      let unreadWorkflows;
      if ($[0] !== healthLogNotesTreatment) {
        unreadWorkflows = [CoachDashboardFilters.UnreadMessages];
        if (healthLogNotesTreatment) {
          unreadWorkflows.push(CoachDashboardFilters.MemberHealthLog);
        }
        $[0] = healthLogNotesTreatment;
        $[1] = unreadWorkflows;
      } else {
        unreadWorkflows = $[1];
      }
      let tempAccordionList;
      if ($[2] !== coachVideoVisitSplitOn || $[3] !== unreadWorkflows || $[4] !== coachWeeklyGoalExpiry || $[5] !== coachMemberUpdatedWeeklyGoal) {
        tempAccordionList = [];
        if (coachVideoVisitSplitOn) {
          let t1;
          if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
            t1 = {
              name: [CoachDashboardFilters.CoachVideoVisit],
              id: "0"
            };
            $[7] = t1;
          } else {
            t1 = $[7];
          }
          tempAccordionList.push(t1);
        }
        let t1;
        if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
          t1 = {
            name: [CoachDashboardFilters.CoachCalls],
            id: "1",
            showDivider: true
          };
          $[8] = t1;
        } else {
          t1 = $[8];
        }
        let t2;
        if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
          t2 = {
            name: [CoachDashboardFilters.All],
            category: "",
            showDivider: true,
            id: "2"
          };
          $[9] = t2;
        } else {
          t2 = $[9];
        }
        let t3;
        if ($[10] !== unreadWorkflows) {
          t3 = {
            name: unreadWorkflows,
            id: "3",
            category: PANEL_CATEGORY.UNREADS,
            showCategoryHeader: true,
            showDivider: true
          };
          $[10] = unreadWorkflows;
          $[11] = t3;
        } else {
          t3 = $[11];
        }
        let t4;
        if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
          t4 = {
            name: [CoachDashboardFilters.ReEngager],
            id: "4"
          };
          $[12] = t4;
        } else {
          t4 = $[12];
        }
        let t5;
        if ($[13] === Symbol.for("react.memo_cache_sentinel")) {
          t5 = {
            name: [CoachDashboardFilters.CoachEscalation],
            id: "5"
          };
          $[13] = t5;
        } else {
          t5 = $[13];
        }
        let t6;
        if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
          t6 = [CoachDashboardFilters.FirstWeekCheckIn, CoachDashboardFilters.FirstWeekReview];
          $[14] = t6;
        } else {
          t6 = $[14];
        }
        let t7;
        if ($[15] === Symbol.for("react.memo_cache_sentinel")) {
          t7 = {
            name: t6,
            category: PANEL_CATEGORY.FIRST_WEEK_GOAL,
            id: "6",
            showCategoryHeader: true
          };
          $[15] = t7;
        } else {
          t7 = $[15];
        }
        let t8;
        if ($[16] !== t3) {
          t8 = [t1, t2, t3, t4, t5, t7];
          $[16] = t3;
          $[17] = t8;
        } else {
          t8 = $[17];
        }
        const accordionList = t8;
        tempAccordionList.push(...accordionList);
        let actionPlanWorkflows;
        if ($[18] !== coachWeeklyGoalExpiry || $[19] !== coachMemberUpdatedWeeklyGoal) {
          actionPlanWorkflows = [];
          actionPlanWorkflows.push(CoachDashboardFilters.ActionPlanCheckIn, CoachDashboardFilters.ReviewAndRecalibrate);
          if (coachWeeklyGoalExpiry) {
            actionPlanWorkflows.push(CoachDashboardFilters.CoachWeeklyGoalExpiry);
          }
          if (coachMemberUpdatedWeeklyGoal) {
            actionPlanWorkflows.push(CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal);
          }
          $[18] = coachWeeklyGoalExpiry;
          $[19] = coachMemberUpdatedWeeklyGoal;
          $[20] = actionPlanWorkflows;
        } else {
          actionPlanWorkflows = $[20];
        }
        let t9;
        if ($[21] !== actionPlanWorkflows) {
          t9 = {
            name: actionPlanWorkflows,
            category: PANEL_CATEGORY.ACTION_PLAN,
            id: "7",
            showCategoryHeader: true
          };
          $[21] = actionPlanWorkflows;
          $[22] = t9;
        } else {
          t9 = $[22];
        }
        tempAccordionList.push(t9);
        let t10;
        if ($[23] === Symbol.for("react.memo_cache_sentinel")) {
          t10 = [CoachDashboardFilters.WeeklyEnsoReview];
          $[23] = t10;
        } else {
          t10 = $[23];
        }
        const ensoWorkflows = t10;
        let t11;
        if ($[24] === Symbol.for("react.memo_cache_sentinel")) {
          t11 = {
            name: ensoWorkflows,
            category: PANEL_CATEGORY.ENSO,
            id: "8",
            showCategoryHeader: true
          };
          $[24] = t11;
        } else {
          t11 = $[24];
        }
        tempAccordionList.push(t11);
        let t12;
        if ($[25] === Symbol.for("react.memo_cache_sentinel")) {
          t12 = [CoachDashboardFilters.MaternityMilestones, CoachDashboardFilters.PregnancyStatusChange];
          $[25] = t12;
        } else {
          t12 = $[25];
        }
        let t13;
        if ($[26] === Symbol.for("react.memo_cache_sentinel")) {
          t13 = {
            name: t12,
            category: PANEL_CATEGORY.PELVIC_HEALTH,
            id: "9",
            showCategoryHeader: true
          };
          $[26] = t13;
        } else {
          t13 = $[26];
        }
        tempAccordionList.push(t13);
        let t14;
        if ($[27] === Symbol.for("react.memo_cache_sentinel")) {
          t14 = [CoachDashboardFilters.AlertReminder, CoachDashboardFilters.BalanceAssessment, CoachDashboardFilters.OutreachNeeded];
          $[27] = t14;
        } else {
          t14 = $[27];
        }
        const otherWorkflows = t14;
        let t15;
        if ($[28] === Symbol.for("react.memo_cache_sentinel")) {
          t15 = {
            name: otherWorkflows,
            category: PANEL_CATEGORY.OTHER_WORKFLOWS,
            id: "10",
            showDivider: true,
            showCategoryHeader: true
          };
          $[28] = t15;
        } else {
          t15 = $[28];
        }
        tempAccordionList.push(t15);
        let t16;
        if ($[29] === Symbol.for("react.memo_cache_sentinel")) {
          t16 = {
            name: [CoachDashboardFilters.Completed],
            id: "11"
          };
          $[29] = t16;
        } else {
          t16 = $[29];
        }
        tempAccordionList.push(t16);
        $[2] = coachVideoVisitSplitOn;
        $[3] = unreadWorkflows;
        $[4] = coachWeeklyGoalExpiry;
        $[5] = coachMemberUpdatedWeeklyGoal;
        $[6] = tempAccordionList;
      } else {
        tempAccordionList = $[6];
      }
      t0 = tempAccordionList;
      break bb0;
    } else {
      if (dashboardType === DashboardType.PhysicalTherapist) {
        let t1;
        if ($[30] === Symbol.for("react.memo_cache_sentinel")) {
          t1 = {
            name: [PtDashboardFilters.VideoVisit],
            id: "0"
          };
          $[30] = t1;
        } else {
          t1 = $[30];
        }
        let t2;
        if ($[31] === Symbol.for("react.memo_cache_sentinel")) {
          t2 = {
            name: [PtDashboardFilters.HouseCall],
            id: "1",
            showDivider: true
          };
          $[31] = t2;
        } else {
          t2 = $[31];
        }
        let t3;
        if ($[32] === Symbol.for("react.memo_cache_sentinel")) {
          t3 = {
            name: [PtDashboardFilters.All],
            id: "2",
            showDivider: true
          };
          $[32] = t3;
        } else {
          t3 = $[32];
        }
        let t4;
        if ($[33] === Symbol.for("react.memo_cache_sentinel")) {
          t4 = {
            name: [PtDashboardFilters.UnreadMessage],
            id: "3"
          };
          $[33] = t4;
        } else {
          t4 = $[33];
        }
        let tempAccordionList_0;
        if ($[34] !== setupActionPlanTreatment || $[35] !== isPtActionPlanReviewWorkflowSplitOn) {
          tempAccordionList_0 = [t1, t2, t3, t4];
          let t5;
          if ($[37] === Symbol.for("react.memo_cache_sentinel")) {
            t5 = [PtDashboardFilters.MedicalEmergencyFollowUp, PtDashboardFilters.ClinicalEscalation, PtDashboardFilters.EtModification, PtDashboardFilters.SurgeryTrend, PtDashboardFilters.IndicationUpdate, PtDashboardFilters.AqRedFlags];
            $[37] = t5;
          } else {
            t5 = $[37];
          }
          const clinicalWorkflows = t5;
          let t6;
          if ($[38] === Symbol.for("react.memo_cache_sentinel")) {
            t6 = {
              name: clinicalWorkflows,
              category: PT_PANEL_CATEGORY.CLINICAL_WORKFLOWS,
              id: "4",
              showCategoryHeader: true
            };
            $[38] = t6;
          } else {
            t6 = $[38];
          }
          tempAccordionList_0.push(t6);
          let t7;
          if ($[39] === Symbol.for("react.memo_cache_sentinel")) {
            t7 = [PtDashboardFilters.HealthLogNotes, PtDashboardFilters.PainUptick];
            $[39] = t7;
          } else {
            t7 = $[39];
          }
          let t8;
          if ($[40] === Symbol.for("react.memo_cache_sentinel")) {
            t8 = {
              name: t7,
              id: "5",
              category: PT_PANEL_CATEGORY.HEALTH_LOGS,
              showCategoryHeader: true
            };
            $[40] = t8;
          } else {
            t8 = $[40];
          }
          tempAccordionList_0.push(t8);
          const actionPlanWorkflows_0 = [];
          if (setupActionPlanTreatment) {
            actionPlanWorkflows_0.push(PtDashboardFilters.SetupActionPlan);
          }
          if (isPtActionPlanReviewWorkflowSplitOn) {
            actionPlanWorkflows_0.push(PtDashboardFilters.ActionPlanReview);
          }
          if (!isEmpty(actionPlanWorkflows_0)) {
            let t9;
            if ($[41] !== actionPlanWorkflows_0) {
              t9 = {
                name: actionPlanWorkflows_0,
                category: PT_PANEL_CATEGORY.ACTION_PLANS,
                id: "6",
                showCategoryHeader: true
              };
              $[41] = actionPlanWorkflows_0;
              $[42] = t9;
            } else {
              t9 = $[42];
            }
            tempAccordionList_0.push(t9);
          }
          let t9;
          if ($[43] === Symbol.for("react.memo_cache_sentinel")) {
            t9 = [PtDashboardFilters.FirstWeekEnsoCheckIn, PtDashboardFilters.PelvicTrainerOutreach];
            $[43] = t9;
          } else {
            t9 = $[43];
          }
          let t10;
          if ($[44] === Symbol.for("react.memo_cache_sentinel")) {
            t10 = {
              name: t9,
              category: PT_PANEL_CATEGORY.DEVICES,
              id: "7",
              showCategoryHeader: true
            };
            $[44] = t10;
          } else {
            t10 = $[44];
          }
          tempAccordionList_0.push(t10);
          let t11;
          if ($[45] === Symbol.for("react.memo_cache_sentinel")) {
            t11 = [PtDashboardFilters.ExpiringCarePlans, PtDashboardFilters.DischargeAndClosePlan];
            $[45] = t11;
          } else {
            t11 = $[45];
          }
          let t12;
          if ($[46] === Symbol.for("react.memo_cache_sentinel")) {
            t12 = {
              name: t11,
              category: PT_PANEL_CATEGORY.CARE_PLANS,
              id: "8",
              showCategoryHeader: true
            };
            $[46] = t12;
          } else {
            t12 = $[46];
          }
          tempAccordionList_0.push(t12);
          let t13;
          if ($[47] === Symbol.for("react.memo_cache_sentinel")) {
            t13 = [PtDashboardFilters.PtMemberAlertReminder];
            $[47] = t13;
          } else {
            t13 = $[47];
          }
          let t14;
          if ($[48] === Symbol.for("react.memo_cache_sentinel")) {
            t14 = {
              name: t13,
              category: PT_PANEL_CATEGORY.OTHERS,
              id: "9",
              showCategoryHeader: true,
              showDivider: true
            };
            $[48] = t14;
          } else {
            t14 = $[48];
          }
          tempAccordionList_0.push(t14);
          let t15;
          if ($[49] === Symbol.for("react.memo_cache_sentinel")) {
            t15 = {
              name: [PtDashboardFilters.Completed],
              id: "10"
            };
            $[49] = t15;
          } else {
            t15 = $[49];
          }
          tempAccordionList_0.push(t15);
          $[34] = setupActionPlanTreatment;
          $[35] = isPtActionPlanReviewWorkflowSplitOn;
          $[36] = tempAccordionList_0;
        } else {
          tempAccordionList_0 = $[36];
        }
        t0 = tempAccordionList_0;
        break bb0;
      } else {
        if (dashboardType === DashboardType.OnboardingSpecialist) {
          let tempAccordionList_1;
          if ($[50] === Symbol.for("react.memo_cache_sentinel")) {
            tempAccordionList_1 = [{
              name: [OsDashboardFilters.All],
              id: "0",
              showDivider: true
            }];
            tempAccordionList_1.push({
              name: [OsDashboardFilters.UnreadMessage],
              id: "1",
              category: OS_PANEL_CATEGORY.UNREADS,
              showCategoryHeader: true,
              showDivider: true
            });
            tempAccordionList_1.push({
              name: [OsDashboardFilters.ReEngager],
              id: "2"
            });
            tempAccordionList_1.push({
              name: [OsDashboardFilters.Nuto, OsDashboardFilters.Ruto],
              id: "3",
              category: OS_PANEL_CATEGORY.NUTO_OR_RUTO,
              showCategoryHeader: true
            });
            const otherWorkflows_0 = [OsDashboardFilters.AlertReminder, OsDashboardFilters.OutreachNeeded];
            tempAccordionList_1.push({
              name: otherWorkflows_0,
              category: PANEL_CATEGORY.OTHER_WORKFLOWS,
              id: "4",
              showDivider: true,
              showCategoryHeader: true
            });
            tempAccordionList_1.push({
              name: [OsDashboardFilters.Completed],
              id: "4"
            });
            $[50] = tempAccordionList_1;
          } else {
            tempAccordionList_1 = $[50];
          }
          t0 = tempAccordionList_1;
          break bb0;
        }
      }
    }
    t0 = undefined;
  }
  const accordionList_0 = t0;
  return (accordionList_0 as Array<FilterCategory<T>>);
};