export enum VideoCallStatus {
  Preview = 'Preview',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}
export enum MediaDeviceKind {
  AudioInput = 'audioinput',
  AudioOutput = 'audiooutput',
  VideoInput = 'videoinput',
}
export enum TrackKind {
  Audio = 'audio',
  Video = 'video',
}
export enum RemoteParticipantStatus {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  InTheCall = 'InTheCall',
  NotInTheCall = 'NotInTheCall',
}
export enum CoverageAdminRoles {
  Coach = 'coach',
  PhysicalTherapist = 'physical_therapist',
  OnboardingSpecialist = 'onboarding_specialist',
  Super = 'super',
  MedicalDoctor = 'medical_doctor',
  NursePractitioner = 'nurse_practitioner',
  CareCoordinator = 'care_coordinator',
}