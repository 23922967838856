export const MIXPANEL_EVENTS = {
  WORKFLOW_SELECTED_FROM_DASHBOARD: 'dashboard.workflow_list.clicked',
  WORKFLOW_SELECTED_FROM_QUEUE: 'carehub_home.left_navigation_pane.queue_icon_clicked',
  WORKFLOW_CLICKED_FROM_LIST: 'Workflow clicked from workflows list',
  WORKFLOW_SELECTED: 'dashboard.workflow_list.clicked',
  UNREAD_MESSAGE_SIDEBAR_FILTER_SELECTED: 'Unread message sidebar filter selected from dashboard',
  NO_RESPONSE_PREDICTED_UNREAD_MESSAGES_TAB_SELECTED: 'No response predicted unread messages tab selected',
  ALL_INCOMING_UNREAD_MESSAGES_TAB_SELECTED: 'All incoming unread messages tab selected',
  UNREAD_MESSAGE_WORKFLOW_CHECKED_FROM_CHECKBOX: 'Unread message workflow selected via checkbox',
  UNREAD_MESSAGE_WORKFLOW_RESOLVED_FROM_MARK_READ: 'Unread message workflow marked as read',
  UNREAD_MESSAGE_WORKFLOWS_RESOLVED_FROM_MARK_COMPLETE: 'Unread message workflows marked as complete',
  CARE_COORDINATION_WORKFLOW_FORM_SUBMITTED: 'Care coordination workflow form submitted',
  CARE_COORDINATION_WORKFLOW_FORM_CANCELLED: 'Care coordination workflow form cancelled',
  CARE_COORDINATION_FROM_WORKFLOW_PANEL: 'Care coordination workflow selected from workflow panel',
  CARE_COORDINATION_FROM_MEMBER_RECORD: 'Care coordination workflow selected from member record',
  WORKFLOW_RESOLVED: 'Workflow resolved',
  RESOURCE_LINK_CLICKED: 'Resource link clicked',
  WORKFLOW_DISMISSED: 'Workflow dismissed',
  WORKFLOW_REOPENED: 'Workflow reopened',
  ACTION_COMPLETED: 'Action completed',
  ACTION_UNDONE: 'Action undone',
  NEXT_BOTTON_CLICKED: 'Next button clicked',
  QUEUE_WORKFLOW_CATEGORY_SELECTED: 'Queue workflow category selected',
  NEW_ADMIN_SELECTED: 'New admin selected',
  ALL_INCOMING_HEALTH_LOG_NOTES_TAB_SELECTED: 'All incoming health log notes tab selected',
  NO_RESPONSE_PREDICTED_HEALTH_LOG_NOTES_TAB_SELECTED: 'No response predicted health log notes tab selected',
  HEALTH_LOG_NOTES_SIDEBAR_FILTER_SELECTED: 'Health log notes sidebar filter selected from dashboard',
  WORKFLOW_MANUALLY_RESOLVED_VIA_MARK_AS_READ: 'NRP: Workflow manually resolved via mark as read (NRP)',
  PT_POOLED_DASHBOARD_CHANGED: 'PT Pooled Dashboard Changed to New PT',
  VIDEO_VISITS_UPCOMING_VIDEO_VISITS_TAB_SELECTED: 'Video Visits: Upcoming Video Visits tab selected',
  VIDEO_VISITS_POST_VIDEO_VISIT_TASKS_TAB_SELECTED: 'Video Visits: Post Video Visit Tasks tab selected',
  ELEMENT_CLICKED: 'Element clicked',
  PAIN_UPTICK_SIDEBAR_FILTER_SELECTED: 'HL pain uptick sidebar filter selected',
  VIDEO_VISITS_DASHBOARD_SIDEBAR_FILTER_CLICKED: 'Video Visits Dashboard Sidebar Filter Clicked',
  IN_APP_MASS_MESSAGING_TAB_SELECTED: 'In-app mass messaging tab selected',
  SMS_MASS_MESSAGING_TAB_SELECTED: 'SMS mass messaging tab selected',
  SCHEDULED_MASS_MESSAGE_FILTER_SELECTED: 'Scheduled Mass Message Filter Selected',
  SENT_MASS_MESSAGE_FILTER_SELECTED: 'Sent Mass Message Filter Selected',
  NEW_MASS_MESSAGE_BUTTON_CLICKED: 'New mass message button clicked',
  MASS_MESSAGE_DELETE_CONFIRMATION_CLICKED: 'Mass message delete confirmation clicked',
  EDIT_MASS_MESSAGE_CLICKED: 'Edit mass message clicked',
  MASS_MESSAGE_DELETE_BUTTON_CLICKED: 'Mass message delete button clicked',
  IAM_CHANNEL_SELECTED: 'In-app channel selected from dropdown',
  IAM_TAB_SELECTED: 'In-app tab selected',
  SMS_TAB_SELECTED: 'Sms tab selected',
  WORKFLOW_CARD_WORKFLOW_LIST_CLICKED: 'workflow_card.workflow_list.clicked',
  WORKFLOW_CARD_RESOLVE_WORKFLOW: 'workflow_card.workflow_resolved.clicked',
  WORKFLOW_CARD_VIEW_MORE_CLICKED: 'workflow_card.view_more.clicked',
  WORKFLOW_CARD_VIEW_LESS_CLICKED: 'workflow_card.view_less.clicked',
  WORKFLOW_CARD_OPEN_WORKFLOWS_NEXT_PAGE_CLICKED: 'workflow_card.open_workflows_next_page.clicked',
  WORKFLOW_CARD_CLOSED_WORKFLOW_NEXT_PAGE_CLICKED: 'workflow_card.closed_workflows_next_page.clicked',
  WORKFLOW_AUTOMATICALLY_RESOLVED: 'Workflow automatically resolved',
  VIDEO_VISIT_RECORDING_START_INITIATED: 'Video visit recording start initiated',
  VIDEO_VISIT_RECORDING_STOP_INITIATED: 'Video visit recording stop initiated',
  VIDEO_VISIT_RECORDING_CONFIRMATION_CANCELLED: 'Video visit recording confirmation cancelled',
  VIDEO_VISIT_RECORDING_STARTED: 'Video visit recording started',
  VIDEO_VISIT_RECORDING_STOPPED: 'Video visit recording stopped',
  VIDEO_VISIT_RECORDING_NOTIFICATION_RECEIVED: 'Video visit recording notification received',
  VIDEO_VISIT_RECORDING_PLAYBACK_STARTED: 'Video visit recording playback started',
  VIDEO_VISIT_RECORDING_PLAYBACK_STOPPED: 'Video visit recording playback stopped',
  NBA_WORKFLOW_THUMBS_UP_CLICKED: 'nba_workflow_card.thumbs_up.clicked',
  NBA_WORKFLOW_THUMBS_DOWN_CLICKED: 'nba_workflow_card.thumbs_down.clicked',
  NBA_WORKFLOW_FEEDBACK_SUBMITTED: 'nba_workflow_card.feedback_submitted.clicked',
  NBA_WORKFLOW_FEEDBACK_CANCELED_CLICKED: 'nba_workflow_card.feedback_canceled.clicked'
};
export const CHANNEL_EVENTS = ({
  CARE_TEAM: 'Care Team Channel Established',
  PT_ONE_ON_ONE: 'PT One-on-One Channel Established',
  HC_ONE_ON_ONE: 'HC One-on-One Channel Established'
} as const);