import { c as _c } from "react-compiler-runtime";
import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { NAV_SPLIT_TREATMENTS } from '../constants/strings/split';
import { useCurrentStoredAdminHook } from '../hooks/use-current-stored-admin';
import { FeatureFlagContext } from './feature-flag-context';
import { GET_FEATURE_FLAG_TREATMENTS } from './queries/get-feature-flag.graphql';
const FeatureFlagProvider: React.FC = t0 => {
  const $ = _c(14);
  const {
    children
  } = t0;
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const key = currentStoredAdmin?.uuid || "";
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = Object.values(NAV_SPLIT_TREATMENTS);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const flags = t1;
  let t2;
  if ($[1] !== key) {
    t2 = {
      key,
      flags
    };
    $[1] = key;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  const t3 = !key || flags.length === 0;
  let t4;
  if ($[3] !== t2 || $[4] !== t3) {
    t4 = {
      variables: t2,
      skip: t3
    };
    $[3] = t2;
    $[4] = t3;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const {
    data
  } = useQuery(GET_FEATURE_FLAG_TREATMENTS, t4);
  const [featureFlags, setFeatureFlags] = useState(null);
  let t5;
  let t6;
  if ($[6] !== data) {
    t5 = () => {
      if (data?.getTreatments) {
        const flagsObj = data.getTreatments.treatments;
        setFeatureFlags(flagsObj);
      }
    };
    t6 = [data];
    $[6] = data;
    $[7] = t5;
    $[8] = t6;
  } else {
    t5 = $[7];
    t6 = $[8];
  }
  useEffect(t5, t6);
  let t7;
  let t8;
  if ($[9] !== featureFlags) {
    t8 = {
      featureFlags
    };
    $[9] = featureFlags;
    $[10] = t8;
  } else {
    t8 = $[10];
  }
  t7 = t8;
  let t9;
  if ($[11] !== t7 || $[12] !== children) {
    t9 = <FeatureFlagContext.Provider value={t7}>{children}</FeatureFlagContext.Provider>;
    $[11] = t7;
    $[12] = children;
    $[13] = t9;
  } else {
    t9 = $[13];
  }
  return t9;
};
export default FeatureFlagProvider;